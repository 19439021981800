<template>
    <div id="knowledge-base-article">
        <b-row>
            <b-col lg="12" md="12">
                <b-card>

                    <!-- Title -->
                    <b-card-title class="mb-1">
                        <feather-icon icon="SmartphoneIcon" size="21" />

                        {{ kb_question.title }}
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Last updated on {{ moment(kb_question.updated_at).format('LLL') }}
                    </b-card-text>

                    <!-- Content -->
                    <!-- eslint-disable vue/no-v-html -->
                    <div class="question-content" v-html="kb_question.content" />
                    <!-- eslint-enable -->
                </b-card>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import {
    BRow, BCol, BCard, BListGroup, BListGroupItem, BCardText, BCardTitle,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BListGroup,
        BCardText,
        BCardTitle,
        BListGroupItem,
    },
    data() {
        return {
            kb_question: {},
        }
    },
    mounted() {
        this.getDoc()
    },
    methods: {
        getDoc(){
            axios.get(`/docs/${this.$route.params.slug}`)
            .then(res => {
                console.log(res);
                this.kb_question = res.data
            })
            .catch(error => {
                console.log(error);
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>